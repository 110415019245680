<template>
  <section>
    <breadcrumb :title="$t('accounting')" :subtitle="$t('transfers')"/>
    <div class="columns filters is-flex is-justify-content-space-between">
      <div class="column is-flex is-align-items-center">
        <div>
          <b-field>
            <b-input :placeholder="$t('filter_search')"
                     custom-class="search-filter"
                     type="search"
                     icon="magnify"
                     icon-clickable
            >
            </b-input>
          </b-field>
        </div>
        <div class="ml-15">
          <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                  class="mx-4 cursor-pointer"></b-icon>
        </div>
      </div>
    </div>
    <b-table
        :data="desserts"
        ref="table"
        :paginated="false"
        :per-page="itemsPerPage"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page">

      <b-table-column field="id" :label="$t('nro')" width="40" numeric v-slot="props">
        <span class="col id">
           {{ props.row.id }}
        </span>

      </b-table-column>

      <b-table-column field="recipient" :label="$t('recipient')" sortable v-slot="props">
        <template>
          <a class="col recipient" @click="props.toggleDetails(props.row)">
            {{ props.row.recipient.name }}
          </a>
        </template>
      </b-table-column>

      <b-table-column field="amount" :label="$t('amount2')" sortable v-slot="props">
        <span class="col amount">
           {{ props.row.amount }}
        </span>
      </b-table-column>

      <b-table-column field="card" :label="$t('card')" sortable v-slot="props">
        <span class="col card">
           {{ props.row.source }}
        </span>
      </b-table-column>

      <b-table-column field="card" :label="$t('card')" sortable v-slot="props">
        <span class="col card">
           {{ new Date(props.row.fecha).toLocaleDateString() }}
        </span>
      </b-table-column>
      <b-table-column field="confirmation" :label="$t('confirmation')" sortable>
        <span class="col confirmation">

        </span>
      </b-table-column>

      <b-table-column field="Estado" :label="$t('state')" sortable centered v-slot="props">
        <b-tag type="is-tag-success success" rounded>
          <span class="text-status pending"> {{ props.row.status }}</span>
        </b-tag>
      </b-table-column>

    </b-table>
  </section>
</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";

export default {
  name: "TransfersComponent",
  components: {Breadcrumb},
  data() {
    return {
      isModalDealerActive: false,
      filter: false,
      itemsPerPage: 0,
      currentPage: 0,
      totalPages: 0,
      totalRecords: 0,
      desserts: []
    }

  }
}
</script>

<style scoped>

</style>